<template>
  <div class="row items-push">

    <div class="col-sm-4 col-xxl-4">
      <!-- Pending Orders -->
      <div class="block block-rounded d-flex flex-column h-100 mb-0">
        <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
          <dl class="mb-0">
            <dt class="fs-3 fw-bold">{{ user.naskah_count }}</dt>
            <dd class="fs-sm fw-medium fs-sm fw-medium text-muted mb-0">Naskah Terbit</dd>
          </dl>
          <div class="item item-rounded-lg bg-body-light">
            <i class="far fa-gem fs-3 text-primary"></i>
          </div>
        </div>
      </div>
      <!-- END Pending Orders -->
    </div>
    <div class="col-sm-4 col-xxl-4">
      <!-- New Customers -->
      <div class="block block-rounded d-flex flex-column h-100 mb-0">
        <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
          <dl class="mb-0">
            <dt class="fs-3 fw-bold">{{ user.market_count }}</dt>
            <dd class="fs-sm fw-medium fs-sm fw-medium text-muted mb-0">Naskah di Market</dd>
          </dl>
          <div class="item item-rounded-lg bg-body-light">
            <i class="far fa-user-circle fs-3 text-primary"></i>
          </div>
        </div>
      </div>
      <!-- END New Customers -->
    </div>
    <div class="col-sm-4 col-xxl-4">
      <!-- Messages -->
      <div class="block block-rounded d-flex flex-column h-100 mb-0">
        <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
          <dl class="mb-0">
            <dt class="fs-3 fw-bold">{{ user.sales_count }}</dt>
            <dd class="fs-sm fw-medium fs-sm fw-medium text-muted mb-0">Terjual</dd>
          </dl>
          <div class="item item-rounded-lg bg-body-light">
            <i class="far fa-paper-plane fs-3 text-primary"></i>
          </div>
        </div>
      </div>
      <!-- END Messages -->
    </div>

    <div class="col-sm-6 col-xxl-4">
      <!-- Conversion Rate -->
      <div class="block block-rounded d-flex flex-column h-100 mb-0">
        <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
          <dl class="mb-0">
            <dt class="fs-3 fw-bold">{{ user.balance | currency }}</dt>
            <dd class="fs-sm fw-medium fs-sm fw-medium text-muted mb-0">Pendapatan dari QRCBN Market</dd>
          </dl>
          <div class="item item-rounded-lg bg-body-light">
            <i class="fa-solid fa-money-check-dollar fs-3 text-primary"></i>
          </div>
        </div>
      </div>
      <!-- END Conversion Rate-->
    </div>

    <div class="col-sm-6 col-xxl-4">
      <!-- Conversion Rate -->
      <div class="block block-rounded d-flex flex-column h-100 mb-0">
        <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
          <dl class="mb-0">
            <dt class="fs-3 fw-bold">{{ earnPublish | currency }}</dt>
            <dd class="fs-sm fw-medium fs-sm fw-medium text-muted mb-0">Pendapatan dari Affiliator</dd>
          </dl>
          <div class="item item-rounded-lg bg-body-light">
            <i class="fa-solid fa-money-check-dollar fs-3 text-primary"></i>
          </div>
        </div>
      </div>
      <!-- END Conversion Rate-->
    </div>

    <div class="col-sm-12 col-xxl-4">
      <!-- Conversion Rate -->
      <div class="block block-rounded d-flex flex-column h-100 mb-0">
        <!-- affiliate -->
        <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
          <dl class="mb-0">
            <dt class="fs-3 fw-bold">{{ totalKomisi | currency }}</dt>
            <dd class="fs-sm fw-medium fs-sm fw-medium text-muted mb-0">Komisi kamu sebagai Affiliator</dd>
          </dl>
          <div class="item item-rounded-lg bg-body-light">
            <i class="fa fa-chart-bar fs-3 text-primary"></i>
          </div>
        </div>
        <!-- endAffiliate -->
      </div>
      <!-- END Conversion Rate-->
    </div>

      <div class="col-sm-6 col-xxl-4">
        <!-- Conversion Rate -->
        <div class="block block-rounded d-flex flex-column h-100 mb-0">
          <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
            <dl class="mb-0">
              <dt class="fs-3 fw-bold green--text">{{ user.balance | currency }}</dt>
              <dd class="fs-sm fw-medium fs-sm fw-medium text-muted mb-0 green--text">Total Saldo Masuk</dd>
            </dl>
            <div class="item item-rounded-lg bg-body-light">
              <i class="fa-solid fa-money-check-dollar fs-3 text-primary"></i>
            </div>
          </div>
        </div>
        <!-- END Conversion Rate-->
      </div>

      <div class="col-sm-6 col-xxl-4">
        <!-- Conversion Rate -->
        <div class="block block-rounded d-flex flex-column h-100 mb-0">
          <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
            <dl class="mb-0">
              <!-- <dt class="fs-3 fw-bold red--text">{{ user.balance | currency }}</dt> -->
              <dt class="fs-3 fw-bold red--text">{{ saldoDitarik | currency }}</dt>
              <dd class="fs-sm fw-medium fs-sm fw-medium text-muted mb-0 red--text">Total Saldo Ditarik</dd>
            </dl>
            <div class="item item-rounded-lg bg-body-light">
              <i class="fa-solid fa-money-check fs-3 text-primary"></i>
            </div>
          </div>
        </div>
        <!-- END Conversion Rate-->
      </div>
    <!-- END Overview -->
    <div class="penarikan text-center">
      <button class="btn btn-primary text-center" @click="showModal = true">Penarikan</button>
    </div>

    <!-- modal penarikan -->
    <div v-if="showModal" class="modal-overlay" @click.self="showModal = false">
      <div class="modal-container">
        <div class="modal-header">
          <h5>Konfirmasi Penarikan</h5>
          <button class="close-btn" @click="showModal = false">&times;</button>
        </div>
        <div class="modal-body">
          <input v-model="nominalPenarikan" type="number" class="form-control" placeholder="Masukkan nominal" />
          <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" @click="tarikSaldo">Ya, Tarik</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ['user', 'totalKomisi', 'earnPublish'],
  data() {
    return {
      showModal: false,
      nominalPenarikan: '',
      errorMessage: '',
      saldoDitarik: 0,
      saldoDitarikPending: 0
    }
  },
  methods: {
    async tarikSaldo() {
      if(!this.nominalPenarikan || this.nominalPenarikan <= 20000) {
        this.errorMessage = "Nominal penarikan tidak bisa di bawah Rp. 20.000"
        return;
      }
      if(this.nominalPenarikan > this.user.balance) {
        this.errorMessage = "Saldo tidak mencukupi untuk melakukan penarikan"
        return 
      }

      try {
        const response = await axios.post(
          "https://my.qrcbn.com/api/penarikan/saldo",
          {
            nominal_penarikan: this.nominalPenarikan
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              "Content-Type": "application/json"
            },
          }
        )

        if(response.data.success) {
          alert("Penarikan berhasil, Silahkan tunggu konfirmasi admin");

          this.saldoDitarikPending += parseInt(this.nominalPenarikan)
          this.showModal = false
        } else {
          this.errorMessage = response.data.message
        }
      } catch (error) {
        console.error("Error saat menarik saldo", error);
        this.errorMessage = "Terjadi kesalahan, coba lagi nanti"
      }

      this.showModal = false
      this.nominalPenarikan = ''
      this.errorMessage = ''
    },
    async getSaldoDitarik() {
      try {
        const response = await axios.get("https://my.qrcbn.com/api/penarikan/saldo", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
          }
        })
        if(response.data.success) {
          this.saldoDitarik = response.data.data
        }
      } catch(error) {
        console.error("Error saat mengambil saldo", error)
      }
    } 
  },
  mounted() {
    this.getSaldoDitarik()
  }
};
</script>
<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
</style>

<!-- 
link refrensi split rule xendit 
https://chatgpt.com/share/677e3493-aefc-800a-8942-0e3eceed365e

-->